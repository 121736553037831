<template>
  <b-card v-if="materialData">
    <!-- Form -->
    <b-overlay :show="loading">
      <validation-observer
        ref="refFormObserver"
        #default="{ handleSubmit, invalid }"
      >
        <b-form @submit.prevent="handleSubmit(onSubmit)">
          <b-row
            align-h="between"
            class="mx-0"
          >
            <div>
              <h3
                v-if="materialData.codigo && !editCodigo"
                class="text-primary"
              >
                Codigo #{{ materialData.codigo }}
                <feather-icon
                  icon="EditIcon"
                  size="16"
                  class="ml-2"
                  @click="editCodigo = true"
                />
              </h3>
              <h3
                v-if="!materialData.codigo && !editCodigo"
                class="text-primary"
              >
                Nuevo material
              </h3>

              <div v-if="editCodigo">
                <validation-provider
                  #default="validationContext"
                  name="codigo"
                  rules="required"
                >
                  <b-form-group
                    label="Código*"
                    label-for="codigo"
                    :state="getValidationState(validationContext)"
                  >
                    <b-form-input
                      id="codigo"
                      v-model="materialData.codigo"
                      placeholder="100"
                      :state="getValidationState(validationContext)"
                      type="text"
                    />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </div>
            </div>
            <!-- <b-row class="mx-0" align-v="center">
              <h3 class="text-secondary mr-2">
                Stock atual: {{ materialData.stock }}
              </h3>
              <b-button variant="outline-primary" @click="stockModal = true"
                >Gerenciar</b-button
              >
            </b-row> -->
          </b-row>
          <b-row align-v="center">
            <!-- FK Proveedor -->
            <b-col
              cols="12"
              lg="5"
              md="5"
              sm="6"
            >
              <validation-provider
                #default="validationContext"
                name="proveedor"
                rules="required"
              >
                <b-form-group
                  label="Proveedor*"
                  label-for="proveedor"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="materialData.fk_proveedor"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="proveedores"
                    :reduce="(val) => val.id"
                    :clearable="false"
                    input-id="proveedor"
                    placeholder="Seleccione"
                    label="nombre_comercial"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: Coste -->
            <b-col
              cols="12"
              lg="4"
              md="4"
              sm="3"
            >
              <validation-provider
                #default="validationContext"
                name="Coste"
                rules="required"
              >
                <b-form-group
                  label="Coste* (€)"
                  label-for="coste"
                >
                  <b-form-input
                    id="coste"
                    v-model="materialData.coste"
                    placeholder="100"
                    :state="getValidationState(validationContext)"
                    type="number"
                    step="0.01"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: Coste Diario -->
            <b-col
              cols="12"
              lg="3"
              md="3"
              sm="3"
            >
              <validation-provider
                #default="validationContext"
                name="Coste Diario"
              >
                <b-form-group
                  label="Coste Diario (€)"
                  label-for="coste_diario"
                >
                  <b-form-input
                    id="coste_diario"
                    v-model="materialData.coste_diario"
                    placeholder="100"
                    :state="getValidationState(validationContext)"
                    type="number"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: Barcode -->
            <b-col
              cols="12"
              lg="4"
              md="4"
              sm="4"
            >
              <validation-provider
                #default="validationContext"
                name="Barcode"
                rules="max:50"
              >
                <b-form-group
                  label="Barcode"
                  label-for="Barcode"
                >
                  <b-form-input
                    id="Barcode"
                    v-model="materialData.barcode"
                    placeholder="100"
                    :state="getValidationState(validationContext)"
                    type="number"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: Tipo -->
            <b-col
              cols="12"
              md="6"
              lg="4"
            >
              <validation-provider
                #default="validationContext"
                name="Tipo"
                rules="required"
              >
                <b-form-group
                  label="Tipo*"
                  label-for="tipo"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="materialData.tipo"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="tipos"
                    :reduce="(val) => val.value"
                    :clearable="false"
                    placeholder="Seleccione"
                    input-id="tipo"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: Medida -->
            <b-col
              v-if="showMedidaField"
              cols="12"
              md="6"
              lg="4"
            >
              <validation-provider
                #default="validationContext"
                name="Medida"
                rules="required"
              >
                <b-form-group
                  label="Medida*"
                  label-for="medida"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="materialData.medida"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="medidas"
                    :reduce="(val) => val.value"
                    :clearable="false"
                    input-id="medida"
                    placeholder="Seleccione"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: Talla Pantalon -->
            <b-col
              v-if="showTallaField"
              cols="12"
              md="6"
              lg="4"
            >
              <validation-provider
                #default="validationContext"
                name="Talla Pantalón"
              >
                <b-form-group
                  label="Talla Pantalón"
                  label-for="talla-pantalon"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    id="talla-pantalon"
                    v-model="materialData.tallaPantalon"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="tallasPantalon"
                    :reduce="(val) => val.value"
                    :clearable="false"
                    input-id="talla-pantalon"
                    placeholder="Seleccione"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: Talla Camiseta -->
            <b-col
              v-if="showTallaField"
              cols="12"
              md="6"
              lg="4"
            >
              <validation-provider
                #default="validationContext"
                name="Talla Camiseta"
              >
                <b-form-group
                  label="Talla Camiseta"
                  label-for="talla-camiseta"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    id="talla-camiseta"
                    v-model="materialData.tallaCamiseta"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="tallas"
                    :reduce="(val) => val.value"
                    :clearable="false"
                    input-id="talla-camiseta"
                    placeholder="Seleccione"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: Color -->
            <b-col
              v-if="showColorField"
              cols="12"
              md="6"
              lg="4"
            >
              <validation-provider
                #default="validationContext"
                name="Color"
              >
                <b-form-group
                  label="Color"
                  label-for="color"
                >
                  <b-form-input
                    id="color"
                    v-model="materialData.color"
                    placeholder="Azul"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: Stock -->
            <b-col
              cols="12"
              md="6"
              lg="4"
            >
              <validation-provider
                #default="validationContext"
                name="Stock"
                rules="min_value:0"
              >
                <b-form-group
                  label="Stock"
                  label-for="stock"
                >
                  <b-form-input
                    id="stock-minino"
                    v-model="materialData.stock"
                    placeholder="100"
                    :state="getValidationState(validationContext)"
                    type="number"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: Stock Minino -->
            <b-col
              cols="12"
              md="6"
              lg="4"
            >
              <validation-provider
                #default="validationContext"
                name="Stock Minimo"
              >
                <b-form-group
                  label="Stock Minimo"
                  label-for="stock-minimo"
                >
                  <b-form-input
                    id="stock-minino"
                    v-model="materialData.stock_minimo"
                    placeholder="10"
                    :state="getValidationState(validationContext)"
                    type="number"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!--- Field: Descripcion -->
            <b-col
              cols="12"
              lg="6"
              md="6"
            >
              <validation-provider
                #default="validationContext"
                name="Descripcion"
                rules="required"
              >
                <b-form-group
                  label="Descripcion Proveedor*"
                  label-for="descripcion"
                >
                  <b-form-textarea
                    id="descripcion"
                    v-model="materialData.descripcion"
                    placeholder="Descripcion"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!--- Field: Descripcion_custom -->
            <b-col
              cols="12"
              lg="6"
              md="6"
            >
              <b-form-group
                label="Descripcion MSC"
                label-for="descripcion"
              >
                <b-form-textarea
                  id="descripcion"
                  v-model="materialData.descripcion_custom"
                  placeholder="Descripcion personalizada"
                />
              </b-form-group>
            </b-col>

            <!--- Field: Nota -->
            <b-col
              cols="12"
              lg="6"
              md="6"
            >
              <validation-provider
                #default="validationContext"
                name="Nota"
              >
                <b-form-group
                  label="Nota"
                  label-for="nota"
                >
                  <b-form-textarea
                    id="nota"
                    v-model="materialData.nota"
                    placeholder="Nota"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <hr style="border-top: 1px solid gainsboro">

          <b-row align-v="center">
            <b-col>
              <b-row class="pl-1 pt-1">
                <!-- Fields: desechable -->
                <p
                  class="mr-1"
                  style="font-weight: bold"
                >
                  ¿Es desechable?
                </p>
                <b-form-checkbox
                  v-model="materialData.desechable"
                  name="check-button"
                  switch
                >
                  <span style="font-weight: 500">{{
                    materialData.desechable ? "Sí" : "No"
                  }}</span>
                </b-form-checkbox>
              </b-row>
            </b-col>
            <b-col>
              <!-- Field: Estado -->
              <validation-provider
                #default="validationContext"
                name="Estado"
                rules="required"
              >
                <b-form-group
                  label="Estado*"
                  label-for="estado"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="materialData.estado"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="estados"
                    :reduce="(val) => val.value"
                    :clearable="false"
                    input-id="estado"
                    placeholder="Seleccione"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row class="mt-2">
            <b-col>
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                type="submit"
                :disabled="invalid"
              >
                Guardar cambios
              </b-button>
              <b-button
                variant="outline-secondary"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                :to="{ name: 'materiales-list' }"
              >
                Cancelar
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-overlay>

    <!-- <b-modal
      ok-only
      @ok="stockModal = false"
      :ok-disabled="materialData.stock < 0"
      v-model="stockModal"
      size="md"
      title="Sumar o restar del stock"
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
    >
      <validation-provider
        #default="validationContext"
        name="Stock"
        rules="min_value:0"
      >
        <b-form-group label-for="stock">
          <b-input-group>
            <b-input-group-prepend>
              <b-button
                :disabled="materialData.stock <= 0"
                @click="subtractFromStock()"
                variant="outline-primary"
              >
                <feather-icon icon="MinusIcon" />
              </b-button>
            </b-input-group-prepend>
            <b-form-input
              class="text-center"
              id="stock"
              v-model="materialData.stock"
              placeholder="100"
              :state="getValidationState(validationContext)"
              type="number"
            />
            <b-input-group-append>
              <b-button
                @click="addToStock()"
                variant="outline-primary"
              >
                <feather-icon icon="PlusIcon" /> </b-button
            ></b-input-group-append>
          </b-input-group>
          <b-form-invalid-feedback
            :state="getValidationState(validationContext)"
          >
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-modal> -->
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BButton,
  BFormInvalidFeedback,
  BFormTextarea,
  BOverlay,
  BModal,
  BInputGroupAppend,
  BInputGroup,
  BInputGroupPrepend,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, onUnmounted } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import router from '@/router'
import store from '@/store'
import vSelect from 'vue-select'
import { materiales } from '@/utils/materiales'
import Material from '@/models/Material'
import useProveedoresList from '@/views/system/proveedor/useProveedoresList'
import proveedorStoreModule from '@/views/system/proveedor/proveedorStoreModule'
import materialesStoreModule from './materialesStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BButton,
    BFormInvalidFeedback,
    BFormTextarea,
    vSelect,
    BOverlay,
    BModal,
    BInputGroupAppend,
    BInputGroup,
    BInputGroupPrepend,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },

  data() {
    return {
      required,
      alphaNum,
      email,
      editCodigo: false,
      stockModal: false,
    }
  },
  computed: {
    loading() {
      return this.$store.state['app-materiales'].loading
    },
    showTallaField() {
      return !!(this.materialData.tipo != 'herramienta'
        && this.materialData.tipo != 'material')
    },
    showColorField() {
      return !!(this.materialData.tipo != 'herramienta'
        && this.materialData.tipo != 'material')
    },
    showMedidaField() {
      return this.materialData.tipo != 'uniforme'
    },
  },

  methods: {
    subtractFromStock() {
      this.materialData.stock = parseInt(this.materialData.stock)
      this.materialData.stock -= 1
    },
    addToStock() {
      this.materialData.stock = parseInt(this.materialData.stock)
      this.materialData.stock += 1
    },
    onSubmit() {
      if (this.materialData.tipo === 'uniforme') this.materialData.medida = 'unidades'

      this.$store
        .dispatch('app-materiales/addMaterial', this.materialData)
        .then(() => {
          this.$router.push({ name: 'materiales-list' })
        })
    },
  },

  setup() {
    const blankmaterialData = new Material({
      desechable: false,
      stock: 0,
      stock_minimo: 0,
    })
    const materialData = ref(null)
    const proveedores = ref([])
    const { medidas } = materiales
    const { tallas } = materiales
    const { tallasPantalon } = materiales
    const { tipos } = materiales
    const { estados } = materiales

    const MATERIALES_APP_STORE_MODULE_NAME = 'app-materiales'
    const PROVEEDOR_APP_STORE_MODULE_NAME = 'app-proveedor'

    const resetmaterialData = () => {
      materialData.value = JSON.parse(JSON.stringify(blankmaterialData))
    }

    // Register module
    if (!store.hasModule(MATERIALES_APP_STORE_MODULE_NAME)) {
      store.registerModule(
        MATERIALES_APP_STORE_MODULE_NAME,
        materialesStoreModule,
      )
    }
    if (!store.hasModule(PROVEEDOR_APP_STORE_MODULE_NAME)) {
      store.registerModule(
        PROVEEDOR_APP_STORE_MODULE_NAME,
        proveedorStoreModule,
      )
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MATERIALES_APP_STORE_MODULE_NAME)) store.unregisterModule(MATERIALES_APP_STORE_MODULE_NAME)
      if (store.hasModule(PROVEEDOR_APP_STORE_MODULE_NAME)) store.unregisterModule(PROVEEDOR_APP_STORE_MODULE_NAME)
    })

    const { listProveedores } = useProveedoresList()

    listProveedores().then(response => {
      proveedores.value = response
    })

    if (router.currentRoute.params.id !== 'new') {
      store
        .dispatch('app-materiales/fetchMaterial', router.currentRoute.params.id)
        .then(response => {
          // eslint-disable-next-line no-param-reassign
          response.desechable = !!response.desechable
          materialData.value = response
        })
        .catch(error => {
          if (error.response.status === 404) {
            materialData.value = undefined
          }
        })
    } else {
      materialData.value = blankmaterialData
    }

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetmaterialData)

    return {
      materialData,
      refFormObserver,
      getValidationState,
      resetForm,

      proveedores,
      medidas,
      tallas,
      tipos,
      estados,
      tallasPantalon,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
